const loadComponent = (component) => {
  return () => import(`@/views/${component}.vue`);
};

export default [
  {
    path: "/",
    redirect: "/home",
    name: "Main",
    component: loadComponent("Main"),
    children: [
      {
        path: "/home",
        name: "HomeView",
        component: loadComponent("Home"),
      },
      {
        path: "/about",
        name: "AboutView",
        component: loadComponent("About"),
      },
      {
        path: "/:projectName",
        name: "ProjectView",
        component: loadComponent("Project"),
      },
    ],
  },
];
